declare const device: any;
export default class Utils {
  constructor() { }

  static convertToHex(user: string) {
    let hex = '';
    if (user === null) { return hex; }
    for (let i = 0; i < user.length; i++) {
      hex += '' + user.charCodeAt(i).toString(16);
    }
    return hex;
  }

  static userDbName(user: string) {
    return 'userdb-' + Utils.convertToHex(user);
  }
  static deviceID(): string {
    let dev = typeof device !== 'undefined' ? device.uuid : 'browser-debug';
    return dev;
  }
}
