import PouchDB from 'pouchdb';
/*
export interface ICase {
  Num: number;
  Year: number;
  Details: string;
  Comment: string;
  JudgePanel: string;
  Type: number;
}


export class Case implements PouchDB.Core.IdMeta , ICase {
  constructor(
    public Num: number,
    public Year: number = 0,
    public Details: string = '',
    public Comment: string = '',
    public JudgePanel: string = '',
    public Type: number = 0,
    public _id: string = 'case.' + Type + Year.toString() + '.' + Number.toString() ,
    public _rev?: string
  ) {}
}

*/

export class FavCases implements PouchDB.Core.IdMeta {
  public _id: string = '';
  public _rev?: string;

  constructor(
    public deivceID: string,
    public caseID: string[] = [],
    public userID: string='',
    public registrationID: string=''
  ){
    this._id = deivceID;
  }
}


export class Case implements PouchDB.Core.IdMeta {
  constructor(
      public CaseType: string = '',
      public CaseNum: number = 0,
      public CaseYear: number = 0,
      public Accuser: string = '',
      public Respondent: string = '',
      public Judge: string = '',
      public ActType: string = '',
      public Date: string = '',
      public File: string = '',
      public Reasons: string = '',
      public Hall: string = '',
      public Filter: number = 0,
      public sort: number = 0,
      public Status: string = '',
      public Finished: boolean= false,
      public Current: boolean= false,
      public _id: string = '',
      public _rev?: string

  ) { }
}