//import { LoginService } from './login.service';
import { Injectable, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import PouchDB from 'pouchdb';
import { Router } from '@angular/router';
import { Constants } from '../config/constants';
// PouchDB.plugin(PouchDBAuthentication);
import PouchDBFind from 'pouchdb-find';
PouchDB.plugin(PouchDBFind);

import { dbPair } from './../models/DBPair';
import Utils from '../Utils/Utils';
import { SyncChangeResult } from '../models/Sync';
import { Court } from '../models/courts';
import { Case } from '../models/Cases';

@Injectable({
  providedIn: 'root'
})
export class DbAccessService {


  @Output() updateNeeded = new EventEmitter();

  public scheduleDbName: string = 'cases';

  public get selectedCourt(): string {
    let res = localStorage.getItem('SelectedCourt');
    return res;
  }

  scheduleDB: dbPair;
  signupDB: dbPair;
  personalDB: dbPair;
  courts: Court[] = [];
  syncRunning = false;
  courtsDictionary: Court[]= [];

  public connectedEvent = new EventEmitter<void>();
  public completeEvent = new EventEmitter<void>();
  public changeEvent = new EventEmitter<SyncChangeResult>();
  public pausedEvent = new EventEmitter<void>();
  public activEvent = new EventEmitter<void>();
  public errorEvent = new EventEmitter<void>();

  public get isConnected(): boolean {
    let res = (this.scheduleDB !== undefined && this.signupDB !== undefined && this.personalDB !== undefined);
    return res;
  }

  constructor(private router: Router) {
    this.getCourts();
  }

  Connect(): Promise<boolean> {
    let prom = new Promise<boolean>(async (resolve, reject) => {
      this.scheduleDB = await this.ConnectDB(this.scheduleDbName);
      this.signupDB = await this.ConnectDB('cases-signup');

      const username = localStorage.getItem('IMS_username');
      const userDBName = Utils.userDbName(username);
      this.personalDB = await this.ConnectDBLocal(userDBName);

      this.connectedEvent.emit();
      resolve(true);
    });
    return prom;
  }

  ConnectDBLocal(dbName): Promise<dbPair> {

    let prom = new Promise<dbPair>((resolve, reject) => {
      const localDB: PouchDB.Database = new PouchDB(dbName);
      const res: dbPair = new dbPair(localDB, undefined);
      resolve(res);
    });
    return prom;
  }


  ConnectDB(dbName): Promise<dbPair> {

    let prom = new Promise<dbPair>((resolve, reject) => {
      //      const username = localStorage.getItem('IMS_username');
      //      const password = localStorage.getItem('IMS_pass');
      let username = 'client';
      const password = 'client';
      let localDB: PouchDB.Database;
      let remoteDB: PouchDB.Database;

      if (username !== '') {
        localDB = new PouchDB(dbName);
        remoteDB = new PouchDB(Constants.DB_SERVER + '/' + dbName, {
          auth: { username, password }
        });
        remoteDB.info
          .bind(this)()
          .then(info => {
            console.log(info);
//            this.sync(localDB, remoteDB);
            const res: dbPair = new dbPair(localDB, remoteDB);
            resolve(res);
          });
      } else {
        this.router.navigateByUrl('/login');
        reject();
      }
    });
    return prom;
  }

  sync(db: PouchDB.Database, remoteDB: PouchDB.Database) {
/*
    db.sync(remoteDB, { live: true, retry: true })
      .on('complete', info => {
        console.log('DB sync complete.');
        this.completeEvent.emit();
      })
      .on('paused', info => {
        this.syncRunning = false;
        console.log('Sync Paused');
        this.pausedEvent.emit();
      })
      .on('error', err => {
        console.log(err);
        this.errorEvent.emit();
      });
*/
  }

  get(db: PouchDB.Database, id: string): Promise<PouchDB.Core.IdMeta & PouchDB.Core.GetMeta> {
    const prom = new Promise<PouchDB.Core.IdMeta & PouchDB.Core.GetMeta>(
      async (resolve, reject) => {
        try {
          const doc = await db.get(id);
          resolve(doc);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      }
    );
    return prom;
  }
  getAll(db: PouchDB.Database): Promise<any[]> {
    const prom = new Promise<any[]>(async (resolve, reject) => {
      try {
        const doc = await db.allDocs({
          include_docs: true,
          attachments: true
        });
        const res: any[] = [];
        doc.rows.forEach(row => {
          res.push(row.doc as any);
        });
        resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    return prom;
  }
  getAllByType(db: PouchDB.Database, type: string): Promise<any[]> {
    const prom = new Promise<any[]>(async (resolve, reject) => {
      try {
        const doc = await db.find({
          selector: { Type: { $eq: type } }
        });
        const res: any[] = [];
        doc.docs.forEach(row => {
          res.push(row as any);
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
    return prom;
  }

  getCourts() {
    let username = 'client';
    const password = 'client';
    const remoteDB = new PouchDB(Constants.DB_SERVER + '/courts', {
      auth: { username, password }
    });
    remoteDB.allDocs({
      include_docs: true,
    }).then(res => {
      res.rows.forEach(row => {
        const c = row.doc as Court;
        this.courts.push(c);
        this.courtsDictionary[row.doc._id]= c;
      });
    });
  }
  getRangeByType(
    db: PouchDB.Database,
    type: string,
    start: Moment,
    end: Moment
  ): Promise<any[]> {
    const prom = new Promise<any[]>(async (resolve, reject) => {
      try {
        const filter = {
          selector: {
            $and: [
              { Type: { $eq: type } },
              { Filter: { $gt: parseInt(start.format('YYYYMMDDHH')) } },
              { Filter: { $lt: parseInt(end.format('YYYYMMDDHH')) } }
            ]
          }
        };
        const doc = await db.find(filter);
        const res: any[] = [];
        doc.docs.forEach(row => {
          res.push(row as any);
        });
        resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    return prom;
  }
  getRangeByTypeAndHall(
    db: PouchDB.Database,
    type: string,
    start: Moment,
    end: Moment,
    hall: string
  ): Promise<any[]> {
    const prom = new Promise<any[]>(async (resolve, reject) => {
      try {
        const filter = {
          selector: {
            $and: [
              { Type: { $eq: type } },
              { Hall: { $eq: hall } },
              { Filter: { $gt: parseInt(start.format('YYYYMMDDHHmmss')) } },
              { Filter: { $lt: parseInt(end.format('YYYYMMDDHHmmss')) } }
            ]
          }
        };
        const doc = await db.find(filter);
        const res: any[] = [];
        doc.docs.forEach(row => {
          res.push(row as any);
        });
        resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    return prom;
  }

  getSchedule(
    db: PouchDB.Database,
    date: Moment,
  ): Promise<any[]> {
    const prom = new Promise<any[]>(async (resolve, reject) => {
      try {
        const filter = {
          selector: {
            $and: [
              { Filter: { $eq: parseInt(date.format('YYYYMMDD')) } },
              { _id: { $regex: '^' + localStorage.getItem('SelectedCourt') } },

            ]
          }
        };
        const doc = await db.find(filter);
        const res: any[] = [];
        let docs = doc.docs as Case[];
        docs.forEach((row) => {
          row.sort =  parseInt(  moment(row.Date, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm'));
          res.push(row as any);
        });
        resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    return prom;
  }


  remove(db: PouchDB.Database, id: string) {
    const prom = new Promise<PouchDB.Core.Response>(async (resolve, reject) => {
      this.get(db, id).then(async doc => {
        try {
          const resp = await db.remove(id, doc._rev);
          resolve(resp);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    });
    return prom;
  }

  save(db: PouchDB.Database, item: any) {
    const prom = new Promise<PouchDB.Core.Response>(async (resolve, reject) => {
      this.get(db, item._id)
        .then(async doc => {
          try {
            item._rev = doc._rev;
            const resp = await db.put(item);
            resolve(resp);
          } catch (err) { }
        })
        .catch(async err => {
          console.log(err);
          if (err.name == 'not_found') {
            delete item._rev;
            try {
              const resp = await db.put(item);
              resolve(resp);
            } catch (err) {
              console.log(err);
              reject(err);
            }
          }
        });
    });
    return prom;
  }

  reloadNeeded() {
    this.updateNeeded.emit();
  }

}
