import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
//import { Hall } from '../models/Hall';
import { DbAccessService } from '../services/db-access.service';
import { ScheduleItem } from '../models/ScheduleItem';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Case } from '../models/Cases';
import { CaseDetailsComponent } from '../dialogs/case-details/case-details.component';
//import { timeStamp } from 'console';
import { ActivatedRoute } from "@angular/router";

type AOA = Array<Array<any>>;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'bg-BG' },

  // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
  // `MatMomentDateModule` in your applications root module. We provide it at the component level
  // here, due to limitations of our example generation script.
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }]
})
export class ScheduleComponent implements OnInit {
  court: string;

  dataSource = [];
  halls: string[] = [];
  schedule: Array<Case> = new Array<Case>();
  selectedDate: Date = new Date(Date.now());
  selectedHall: string = 'Всички';

  
  public get filteredSchedule() :  Array<Case> {
    if(this.selectedHall== 'Всички') {
      return this.schedule;
    }
    let sch = this.schedule.filter(elem=> elem.Hall == this.selectedHall );
    return sch;
  }
  
  
  public sched(id: string): ScheduleItem[] {
    if(this.schedule[id] == undefined ){
      return [];
    }
    return this.schedule[id];
  }
  
public hls(): string[]{
  let halls:string [] = [];
  this.schedule.forEach(sch => {
    halls[sch.Hall]= sch.Hall;      
  });
  return Object.keys(halls);
}

  public get start(): Moment {
    return moment(this.selectedDate).startOf('day');
  }
  public get end(): Moment {
    return moment(this.selectedDate).endOf('day');
  }

  dataReady = false;

  constructor(private dba: DbAccessService, private cd: ChangeDetectorRef,public dialog: MatDialog,private route: ActivatedRoute) {
    this.dba.updateNeeded.subscribe(()=>{
      this.loadSchedule();
    })
    if (this.dba.isConnected) {
          this.loadSchedule();
    } else {
      this.dba.connectedEvent.subscribe(res => {
        this.loadSchedule();
        this.dba.changeEvent.subscribe(chres => {
          this.loadSchedule();
        });
        this.dba.pausedEvent.subscribe(res => {
          this.cd.markForCheck();
        });
      });
    }
  }

  getCurrentCourt(): string{
    let sc = localStorage.getItem('SelectedCourt');
    return this.dba.courts.find(c=> c._id == sc).name;
  }
  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      let court = params.get("court");
      let hall = params.get("hall");
      if(court){
        localStorage.setItem('SelectedCourt',court);
      }
      if(hall){
        this.selectedHall= hall;
      }
      this.loadSchedule();
    })
  }
  loadSchedule() {
    let date = moment(this.selectedDate);
    this.dba.getSchedule.bind(this)(this.dba.scheduleDB.remote, date)
      .then((res: Case[]) => {
        this.schedule = res;
        this.schedule.sort( (a,b)=> { return (a.sort > b.sort) ? 1:-1 } )

        let thalls:string [] = [];
        thalls['Всички'] = 'Всички';
        for (const sch of this.schedule) {
          if(sch.Hall=='') { 
            continue;
          };
          thalls[sch.Hall]= sch.Hall; 
        }
        this.halls= Object.keys(thalls).sort();
      })
      .catch(err => {
        console.log(err);
      });
    ;
  }

  onDateChange() {
//    this.readHalls();
    this.loadSchedule();
  }

  getBackground(cs: Case): string {

    if (cs.Finished) return '#aaaaaa55';
    if (cs.Current) return '#00640055';
    
  }
  getTextColor(cs: Case){
    if (cs.Finished) return 'rgba(100, 100, 100, 0.8)';

  }

  refresh() {
    this.cd.markForCheck();
  }
  moreInfo(cs:Case){
    let dialogRef = this.dialog.open(CaseDetailsComponent, {
      panelClass: 'full-width-dialog',
      data: cs,
    });
  }
}
