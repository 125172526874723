import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'stringToMoment'
})
export class StringToMomentPipe implements PipeTransform {

  transform(value: string, args?: any): Moment {
    return moment(value, 'DD.MM.YYYY HH:mm');
  }

}
