import { Pipe, PipeTransform } from '@angular/core';
import { DbAccessService } from '../services/db-access.service';

@Pipe({
  name: 'caseType'
})
export class CaseTypePipe implements PipeTransform {

  caseTypes = { 'civil': 'Гр.Д.', 'criminal':'Н.Д.', 'trade': 'Т.Д.'  };
  constructor(public dba: DbAccessService) {
  }
  
  transform(value: any, args?: any): any {
    let caseType = value.split(':')[1].split('.')[0];
    return this.caseTypes[caseType];
  }

}
