import { Pipe, PipeTransform } from '@angular/core';
import { DbAccessService } from '../services/db-access.service';
import { Court } from '../models/courts';

@Pipe({
  name: 'courtName'
})
export class CourtNamePipe implements PipeTransform {

  constructor(public dba: DbAccessService) {

  }
  transform(value: string, args?: any): string {
    let res ='';
    let courtCode = value.split(':')[0];
    let c = this.dba.courtsDictionary[courtCode] as Court;
    switch (args) {
      case 'short':
        res = c.shortName;
        break;
    
      default:
        res = c.name;
        break;
    }
    return res;
  }

}
