import { Component, OnInit } from '@angular/core';
import { DbAccessService } from './services/db-access.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'InfoManagerKiosk';
  constructor(    
    private dba: DbAccessService,
    ){

  }
  ngOnInit(){
    this.dba.Connect().then(res => {
//      this.title= this.getCurrentCourt();
      console.log(res);
    });
  }
}
