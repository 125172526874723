import { Component, OnInit, Inject, Output, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Case } from 'src/app/models/Cases';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DbAccessService } from 'src/app/services/db-access.service';

import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
PouchDB.plugin(PouchDBFind);
import { Court } from '../../models/courts';
import { CaseType } from '../../models/CaseTypes';

import { Constants } from '../../config/constants';

declare var window: any;

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit, OnDestroy {

  private casesDb: PouchDB.Database;

  constructor(@Inject(MAT_DIALOG_DATA) public cs: Case) {

    let username = 'client';
    const password = 'client';

    this.casesDb = new PouchDB(Constants.DB_SERVER + '/cases', {
      auth: { username, password }
    });
  }

  @Output() addCalendar = new EventEmitter<Case>();
  @Output() addCase = new EventEmitter<Case>();
  @Output() removeCase = new EventEmitter<Case>();

  sessions: Case[] = [];

  async ngOnInit() {
    const caseID = this.cs._id.match(/^[a-z]+:[a-z]+.\d+.\d+/gi);
    if (caseID) {
      this.sessions = await this.loadSessions(caseID[0]);
      console.log(this.sessions);
    }
  }

  ngOnDestroy() {

  }

  createCalendarEvent(cs: Case) {
    var startDate = moment(cs.Date, 'DD.MM.YYYY HH:mm').toDate(); // beware: month 0 = january, 11 = december
    var endDate = moment(cs.Date, 'DD.MM.YYYY HH:mm').clone().add(1, 'hour').toDate();
    var title = 'Дело N' + cs.CaseNum + '/' + cs.CaseYear;
    var location = cs.Hall;
    var notes = 'Дело N' + cs.CaseNum + '/' + cs.CaseYear;
    var success = function (message) { alert('Дело N' + +cs.CaseNum + '/' + cs.CaseYear + ' е добавено към календара Ви.'); };
    var error = function (message) { alert('Грешка пи добавяне на дело N' + +cs.CaseNum + '/' + cs.CaseYear + ' към календара Ви.'); };

    var calOptions = window.plugins.calendar.getCalendarOptions(); // grab the defaults
    //  calOptions.firstReminderMinutes = 120; // default is 60, pass in null for no reminder (alarm)
    //  calOptions.secondReminderMinutes = 5;

    // Added these options in version 4.2.4:
    //  calOptions.recurrence = "monthly"; // supported are: daily, weekly, monthly, yearly
    // calOptions.recurrenceEndDate = new Date(2016,10,1,0,0,0,0,0); // leave null to add events into infinity and beyond
    calOptions.calendarId = cs._id; // Android only, use id obtained from listCalendars() call which is described below. This will be ignored on iOS in favor of calendarName and vice versa. Default: 1.

    // This is new since 4.2.7:
    //    calOptions.recurrenceInterval = 2; // once every 2 months in this case, default: 1

    // And the URL can be passed since 4.3.2 (will be appended to the notes on Android as there doesn't seem to be a sep field)
    //  calOptions.url = "https://www.google.com";

    // on iOS the success handler receives the event ID (since 4.3.6)
    window.plugins.calendar.createEventWithOptions(title, location, notes, startDate, endDate, calOptions, success, error);



    //   window.plugins.calendar.createEvent(title, location, notes, startDate, endDate, success, error);
  }
  hasDate(cs: Case): boolean {
    let startDate = moment(cs.Date, 'DD.MM.YYYY HH:mm').toDate();

    return (startDate.toString() == 'Invalid Date') ? false : true;;
  }

  loadSessions(cid: string): Promise<Case[]> {
    const filter = {
      selector: {
           _id: { '$regex': cid } 
      }
    };
    const prom = new Promise<Case[]>((resolve, reject) => {
      this.casesDb.find(filter).then(res => {
        resolve(res.docs as Case[]);
      })
        .catch(err => {
          reject(err);
        });
    });
    return prom;
  }


}
