<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
    <mat-form-field style="width: 8em;">
      <input matInput [matDatepicker]="dp" placeholder="Дата" [(ngModel)]="selectedDate" (ngModelChange)="onDateChange()" disabled>
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp disabled="false"></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Зала" [(ngModel)]="selectedHall" name="slectHall">
        <mat-option *ngFor="let hall of halls" [value]="hall">
          {{hall}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex class="sch-table">
    <table class="case-table">
      <thead>
        <th>Дело</th>
        <th>Вид</th>
        <th>Начало</th>
        <th>Зала</th>
        <th>Още</th>
      </thead>
      <tbody fxFill>
        <tr *ngFor="let row of filteredSchedule" [ngStyle]="{'background-color': getBackground(row), 'color': getTextColor(row)}"
          (click)="moreInfo(row)">
          <td>{{row.CaseNum}}/{{row.CaseYear}}</td>
          <td>{{row._id | caseType}} </td>
          <td>{{row.Date | stringToMoment |date: 'HH:mm'}} </td>
          <td>{{row.Hall}} </td>
          <td>
            <mat-icon>more_horiz</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
