<div fxFlex="100" fxLayout="column" fxLayoutGap="10px" class="main-case-details">
  <div fxLayout="row" fxLayoutGap="10px">
    <div>Съд:</div>
    <div>{{cs._id | courtName}}</div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div>Тип:</div>
    <div>{{cs.CaseType}}</div>
  </div>
  <div fxLayout="row"  fxLayoutGap="10px">
    <div>Дело:</div>
    <div>{{cs.CaseNum}}/{{cs.CaseYear}} г.</div>
  </div>
  <div fxLayout="row"  fxLayoutGap="10px">
    <div>Ищец:</div>
    <div>{{cs.Accuser}}</div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div>Ответник:</div>
    <div>{{cs.Respondent}}</div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <table mat-table #table [dataSource]="sessions">
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef> Начало </th>
        <td mat-cell *matCellDef="let row"> {{row.Date |stringToMoment| date:'HH:mm'}} <br> {{row.Date |stringToMoment| date:'dd/MM'}}  </td>
      </ng-container>
      <ng-container matColumnDef="Hall">
        <th mat-header-cell *matHeaderCellDef> Зала </th>
        <td mat-cell *matCellDef="let row"> {{row.Hall}} </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> Статус </th>
        <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
      </ng-container>
      <ng-container matColumnDef="Calendar">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-button (click)="createCalendarEvent(row)" *ngIf="hasDate(row)"><mat-icon>calendar_today</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['Date','Hall','Status','Calendar']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['Date','Hall','Status','Calendar'];"></tr>
    </table>
  </div>
  <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="1em">
    <button mat-raised-button mat-dialog-close color="primary">Затвори</button>
  </div>

</div>
